<!--@Time : 2021/11/16 11:00-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<Header></Header>
		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-data-table
					:headers="headers"
					:items="desserts"
					:loading="loading"
					:page.sync="page"
					:items-per-page.sync="itemsPerPage"
					:server-items-length="totalDesserts"
					class="elevation-0"
				>
					<template v-slot:item.action="{ item }">
						<v-icon
							color="primary"
							small
							class="mr-2"
							@click="editItem(item)"
							>mdi-pencil</v-icon
						>
						<v-icon color="error" small @click="deleteItem(item)"
							>mdi-delete</v-icon
						>
					</template>
				</v-data-table>

				<v-btn color="primary" outlined small @click="addItem"
					>Insert an Item</v-btn
				>
			</v-col>
		</v-row>

		<v-dialog v-model="dialog" max-width="600px" @click:outside="clearData">
			<v-card>
				<v-card-title v-if="itemCategory">New Item</v-card-title>
				<v-card-title v-else>Edit Item</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="nameCn" label="Name (CHN)">
							</v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="nameEn" label="Name (ENG)">
							</v-text-field>
						</v-col>
						<v-col cols="6">
							<v-autocomplete
								v-model="country"
								clearable
								:items="countryList"
								item-text="name_en"
								item-value="id"
								label="Country"
								return-object
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="6">
							<v-select
								v-model="team"
								clearable
								:items="teamList"
								item-text="name_en"
								item-value="id"
								label="Team"
								return-object
							>
							</v-select>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text small @click="clearData"
						>Cancel</v-btn
					>
					<v-btn
						v-if="itemCategory"
						color="primary"
						text
						small
						@click="postInstitute"
						>Save</v-btn
					>
					<v-btn
						v-else
						color="primary"
						text
						small
						@click="changeInstitute"
						>Update</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogDelete" max-width="600px">
			<v-card>
				<v-card-title>Confirm delete?</v-card-title>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text small @click="clearData"
						>No</v-btn
					>
					<v-btn color="primary" text small @click="deleteInstitute"
						>Yes</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Header from "@/components/Header";
import { Message } from "element-ui";
import { delContest } from "@/api/solar/contest";
import {
	addInstitute,
	listInstitute,
	updateInstitutePatch,
} from "@/api/solar/institute";
export default {
	name: "Schools",
	components: { Header },
	data() {
		return {
			headers: [
				{ text: "#", value: "index", width: 10 },
				{ text: "Name (CHN)", value: "name_cn", width: 210 },
				{ text: "Name (ENG)", value: "name_en", width: 300 },
				{ text: "Country", value: "country", width: 130 },
				{ text: "Team", value: "team", width: 100 },
				{ text: "Action", value: "action", width: 80 },
			],
			desserts: [],
			loading: false,
			page: 1,
			itemsPerPage: 10,

			totalDesserts: 0,

			itemCategory: false,

			countryList: JSON.parse(
				window.sessionStorage.getItem("country_list")
			),
			teamList: this.$store.getters.team_list,

			nameCn: null,
			nameEn: null,
			country: {},
			team: {},


			dialog: false,
			dialogDelete: false,

			instituteData: null,
		};
	},
	created() {
		this.getDataFromApi();
	},
	watch: {
		page: {
			handler() {
				this.getDataFromApi();
			},
		},
		itemsPerPage: {
			handler() {
				this.getDataFromApi();
			},
		},
	},
	methods: {
		clearData() {
			this.dialog = false;
			this.dialogDelete = false;
			this.instituteData = null;
			this.nameCn = null;
			this.nameEn = null;
			this.country = {};
			this.team = {};
		},

		// 打开添加页面
		addItem() {
			this.clearData();
			this.itemCategory = true;
			this.dialog = true;
		},
		// 打开更新页面
		editItem(item) {
			this.clearData();
			this.itemCategory = false;
			this.dialog = true;
			this.instituteData = item;
			this.nameCn = item.name_cn;
			this.nameEn = item.name_en;
			this.country.id = item.countryId;
			this.team.id = item.teamId;
		},

		// 打开删除确认页面
		deleteItem(item) {
			this.dialogDelete = true;
			this.instituteData = item;
		},

		// 上传一个新数据
		postInstitute() {
			this.dialog = false;
			if (this.$store.getters.team === null) {
				this.desserts = [];
				Message({
					message: "No team is selected",
					type: "warning",
				});
				return;
			}
			addInstitute({
				name_en: this.nameEn,
				name_cn: this.nameCn,
				country: this.country !== {} ? this.country.id : null,
				team: this.team !== {} ? this.team.id : null,
			}).then(() => {
				Message({
					message: "Upload Successful",
					type: "success",
				});
				this.getDataFromApi();
			});
		},

		// 更新数据
		changeInstitute() {
			this.dialog = false;
			updateInstitutePatch(this.instituteData.id, {
				name_en: this.nameEn,
				name_cn: this.nameCn,
				country: this.country !== null ? this.country.id : null,
				team: this.team !== null ? this.team.id : null,
			}).then(() => {
				Message({
					message: "Update Successful",
					type: "success",
				});
				this.getDataFromApi();
			});
		},

		// 删除一个数据
		deleteInstitute() {
			this.dialogDelete = false;
			delContest(this.instituteData.id).then(() => {
				this.desserts.splice(this.instituteData.no - 1, 1);
				this.totalDesserts -= 1;
				this.desserts.forEach((item, index) => {
					item.no = index + 1;
				});
				Message({
					message: "Delete Successful",
					type: "success",
				});
			});
		},

		getDataFromApi() {
			this.loading = true;
			listInstitute({
				page: this.page,
				pagesize: this.pageSize(this.itemsPerPage),
			}).then((res) => {
				this.desserts = [];
				this.totalDesserts = res["count"];
				res["results"].forEach((item, index) => {
					this.desserts.push({
						index: index + 1,
						id: item["id"],
						name_cn: item["name_cn"],
						name_en: item["name_en"],
						countryId:
							item["country"] !== null
								? item["country"]["id"]
								: null,
						country:
							item["country"] !== null
								? item["country"]["name_en"]
								: null,
						teamId:
							item["team"] !== null ? item["team"]["id"] : null,
						team:
							item["team"] !== null
								? item["team"]["name_en"]
								: null,
					});
				});
				this.loading = false;
			});
		},

		pageSize(pagesize) {
			if (pagesize === -1) {
				return 1000;
			} else return pagesize;
		},
	},
};
</script>

<style scoped>
</style>